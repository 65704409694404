import { environment } from '@env/environment';
import { EArchiveType } from '@web/enums';
import { SALE_ENTITY } from './sales.gql';

export const ARCHIVE_BASE_PROPERTIES = `
  ${SALE_ENTITY}
  fragment ArchiveBaseProperty on Archive {
    note
    description
    inventory
    value
    market_estimate
    market_estimate_date
    quantity

    is_sold
    sale {
      data {
        ...SaleEntity
      }
    }

    is_visible_to_editor
    is_visible_to_collaborator
    is_visible_to_ufficio_stile
    is_visible_to_viewer
  }
`;

export const ARCHIVE_IMAGES_ENTITY = `
  fragment ArchiveImagesEntity on ArchiveEntity {
    id
    attributes {
      inventory
      images {
        data {
          ...ImageEntity
        }
      }
    }
  }
`;

export function getArchiveGalleryGql() {
  switch (environment.archiveType) {
    case EArchiveType.FASHION:
      return require('@web/dynamic-modules/fashion/services/graph-ql/fashion-archives.gql').ARCHIVE_GALLERY;
    case EArchiveType.MULTIMEDIA:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-archives.gql')
        .ARCHIVE_GALLERY;
    case EArchiveType.CELLAR:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/cellar/services/graph-ql/cellar-archives.gql').ARCHIVE_GALLERY;
    default:
      return null;
  }
}

export function getArchiveWithAllRelationsEntityGql() {
  switch (environment.archiveType) {
    case EArchiveType.FASHION:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/fashion/services/graph-ql/fashion-archives.gql')
        .ARCHIVE_WITH_ALL_RELATIONS_ENTITY;
    case EArchiveType.MULTIMEDIA:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-archives.gql')
        .ARCHIVE_WITH_ALL_RELATIONS_ENTITY;
    case EArchiveType.CELLAR:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/cellar/services/graph-ql/cellar-archives.gql')
        .ARCHIVE_WITH_ALL_RELATIONS_ENTITY;
    default:
      return null;
  }
}

export function getArchivePrintDataGql() {
  switch (environment.archiveType) {
    case EArchiveType.FASHION:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/fashion/services/graph-ql/fashion-archives.gql')
        .ARCHIVE_PRINT_DATA;
    case EArchiveType.MULTIMEDIA:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-archives.gql')
        .ARCHIVE_PRINT_DATA;
    case EArchiveType.CELLAR:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/cellar/services/graph-ql/cellar-archives.gql').ARCHIVE_PRINT_DATA;
    default:
      return null;
  }
}

export function getLoanPrintDataGql() {
  switch (environment.archiveType) {
    case EArchiveType.FASHION:
      return require('@web/dynamic-modules/fashion/services/graph-ql/fashion-archives.gql').LOAN_PRINT_DATA;
    case EArchiveType.MULTIMEDIA:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-archives.gql')
        .LOAN_PRINT_DATA;
    case EArchiveType.CELLAR:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/cellar/services/graph-ql/cellar-archives.gql').LOAN_PRINT_DATA;
    default:
      return null;
  }
}

export function getSalePrintDataGql() {
  switch (environment.archiveType) {
    case EArchiveType.FASHION:
      return require('@web/dynamic-modules/fashion/services/graph-ql/fashion-archives.gql').SALE_PRINT_DATA;
    case EArchiveType.MULTIMEDIA:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-archives.gql')
        .SALE_PRINT_DATA;
    case EArchiveType.CELLAR:
      // eslint-disable-next-line max-len
      return require('@web/dynamic-modules/cellar/services/graph-ql/cellar-archives.gql').SALE_PRINT_DATA;
    default:
      return null;
  }
}
