import { Injectable } from '@angular/core';

import { IBitfUiRoleManagerService } from '@interfaces';
import { ERoleActions, ERoles } from '@enums';
import { StoreService } from '@services';
import { ArchiveLoan, ArchiveSale, Folder, Loan, Sale } from '@models';

@Injectable({
  providedIn: 'root',
})
export class UiRoleManagerService implements IBitfUiRoleManagerService {
  constructor(private storeService: StoreService) {}

  canI(action, data?: any): boolean {
    const isAdmin = this.storeService.store.user.role.name === ERoles.ADMIN;
    const isEditor = this.storeService.store.user.role.name === ERoles.EDITOR;
    const isCollaborator = this.storeService.store.user.role.name === ERoles.COLLABORATOR;
    const isViewer = this.storeService.store.user.role.name === ERoles.VIEWER;
    const isUfficioStile = this.storeService.store.user.role.name === ERoles.UFFICIO_STILE;

    const isUfficioOrEditorOrCollaboratorOrAdmin = isUfficioStile || isEditor || isCollaborator || isAdmin;
    const isEditorOrCollaboratorOrAdmin = isEditor || isCollaborator || isAdmin;
    const isEditorOrCollaborator = isEditor || isCollaborator;
    const isEditorOrAdmin = isEditor || isAdmin;
    let folder: Folder;
    let loan: Loan;
    let archiveLoan: ArchiveLoan;
    let sale: Sale;
    let archiveSale: ArchiveSale;

    switch (action) {
      case ERoleActions.VIEW_BULK_ACTIONS:
      case ERoleActions.VIEW_FOLDER_ACTIONS:
      case ERoleActions.CREATE_FOLDER:
      case ERoleActions.MANAGE_VIEWER_FOLDERS:
      case ERoleActions.MANAGE_VIEWER_LOANS:
        return isUfficioOrEditorOrCollaboratorOrAdmin;

      case ERoleActions.MANAGE_FOLDERS:
      case ERoleActions.MANAGE_LOANS:
      case ERoleActions.MANAGE_SALES:
      case ERoleActions.MANAGE_UFFICIO_STILE_FOLDERS:
      case ERoleActions.MANAGE_UFFICIO_STILE_LOANS:
        return isEditorOrCollaboratorOrAdmin;

      case ERoleActions.MANAGE_COLLABORATOR_FOLDERS:
      case ERoleActions.MANAGE_COLLABORATOR_LOANS:
      case ERoleActions.EDIT_ITEM:
        return isEditorOrAdmin;

      case ERoleActions.MANAGE_UFFICIO_STILE_NOTE:
        return isAdmin || isUfficioStile;

      case ERoleActions.VIEW_VALUE:
      case ERoleActions.EDIT_VALUE:
      case ERoleActions.CREATE_ITEM:
      case ERoleActions.DELETE_ITEM:
      case ERoleActions.MANAGE_EDITOR_FOLDERS:
      case ERoleActions.MANAGE_EDITOR_LOANS:
      case ERoleActions.SEE_VISIBILITY_FILTER:
      case ERoleActions.MANAGE_VIEWER_ARCHIVES:
      case ERoleActions.MANAGE_UFFICIO_STILE_ARCHIVES:
      case ERoleActions.MANAGE_COLLABORATOR_ARCHIVES:
      case ERoleActions.MANAGE_EDITOR_ARCHIVES:
      case ERoleActions.BULK_EDIT_LOAN_ITEMS:
      case ERoleActions.BULK_EDIT_SALE_ITEMS:
        return isAdmin;

      case ERoleActions.VIEW_FOLDER:
        folder = data;
        return (
          folder.id &&
          (isAdmin ||
            (isEditor && folder.is_visible_to_editor) ||
            (isCollaborator && folder.is_visible_to_collaborator) ||
            (isUfficioStile && folder.is_visible_to_ufficio_stile) ||
            (isViewer && folder.is_visible_to_viewer))
        );
      case ERoleActions.UPDATE_FOLDER:
      case ERoleActions.DELETE_FOLDER:
        folder = data;
        return folder?.id && (isAdmin || folder.created_by_user.email === this.storeService.store.user.email);

      case ERoleActions.VIEW_LOAN:
        loan = data;
        return (
          loan.id &&
          (isAdmin ||
            (isEditor && loan.is_visible_to_editor) ||
            (isCollaborator && loan.is_visible_to_collaborator) ||
            (isUfficioStile && loan.is_visible_to_ufficio_stile) ||
            (isViewer && loan.is_visible_to_viewer))
        );
      case ERoleActions.EDIT_LOAN:
        loan = data as Loan;
        if (isAdmin || (isEditorOrCollaborator && loan?.isClosed === false)) {
          return true;
        }
        return false;
      case ERoleActions.EDIT_SALE:
        sale = data as Sale;
        if (isAdmin || isEditorOrCollaborator) {
          return true;
        }
        return false;

      case ERoleActions.EDIT_LOAN_ITEM:
        loan = data.item as Loan;
        archiveLoan = new ArchiveLoan({}, data.archiveItem);
        if (isAdmin || (isEditorOrCollaborator && archiveLoan?.isIn === false)) {
          return true;
        }
        return false;
      case ERoleActions.EDIT_SALE_ITEM:
        sale = data as Sale;
        archiveSale = new ArchiveSale({}, data.archiveItem);
        if (isAdmin || isEditorOrCollaborator) {
          return true;
        }
        return false;

      case ERoleActions.DELETE_LOAN:
      case ERoleActions.DELETE_SALE:
        return isAdmin;
      default:
        return false;
    }
  }
}
