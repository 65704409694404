export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  ADMIN = 'admin',
  VIEWER = 'viewer',
  COLLABORATOR = 'collaborator',
  EDITOR = 'editor',
  UFFICIO_STILE = 'ufficio_stile',
}

export enum ERoleActions {
  VIEW_BULK_ACTIONS = 'VIEW_BULK_ACTIONS',

  // ITEMS
  EDIT_ITEM = 'EDIT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  CREATE_ITEM = 'CREATE_ITEM',
  VIEW_VALUE = 'VIEW_VALUE',
  EDIT_VALUE = 'EDIT_VALUE',
  PRINT_VALUE = 'PRINT_VALUE',

  // ARCHIVES
  EDIT_ARCHIVE_ITEM = 'EDIT_ARCHIVE_ITEM',
  MANAGE_VIEWER_ARCHIVES = 'MANAGE_VIEWER_ARCHIVES',
  MANAGE_UFFICIO_STILE_ARCHIVES = 'MANAGE_UFFICIO_STILE_ARCHIVES',
  MANAGE_COLLABORATOR_ARCHIVES = 'MANAGE_COLLABORATOR_ARCHIVES',
  MANAGE_EDITOR_ARCHIVES = 'MANAGE_EDITOR_ARCHIVES',
  SEE_VISIBILITY_FILTER = 'SEE_VISIBILITY_FILTER',

  // FOLDERS
  VIEW_FOLDER = 'VIEW_FOLDER',
  VIEW_FOLDER_ACTIONS = 'VIEW_FOLDER_ACTIONS',
  CREATE_FOLDER = 'CREATE_FOLDER',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  MANAGE_FOLDERS = 'MANAGE_FOLDERS',
  MANAGE_UFFICIO_STILE_FOLDERS = 'MANAGE_UFFICIO_STILE_FOLDERS',
  MANAGE_VIEWER_FOLDERS = 'MANAGE_VIEWER_FOLDERS',
  MANAGE_COLLABORATOR_FOLDERS = 'MANAGE_COLLABORATOR_FOLDERS',
  MANAGE_EDITOR_FOLDERS = 'MANAGE_EDITOR_FOLDERS',

  // LOANS
  VIEW_LOAN = 'VIEW_LOAN',
  EDIT_LOAN = 'EDIT_LOAN',
  EDIT_LOAN_ITEM = 'EDIT_LOAN_ITEM',
  DELETE_LOAN = 'DELETE_LOAN',
  MANAGE_LOANS = 'MANAGE_LOANS',
  MANAGE_UFFICIO_STILE_NOTE = 'MANAGE_UFFICIO_STILE_NOTE',
  MANAGE_UFFICIO_STILE_LOANS = 'MANAGE_UFFICIO_STILE_LOANS',
  MANAGE_VIEWER_LOANS = 'MANAGE_VIEWER_LOANS',
  MANAGE_COLLABORATOR_LOANS = 'MANAGE_COLLABORATOR_LOANS',
  MANAGE_EDITOR_LOANS = 'MANAGE_EDITOR_LOANS',
  BULK_EDIT_LOAN_ITEMS = 'BULK_EDIT_LOAN_ITEMS',

  // SALES
  EDIT_SALE = 'EDIT_SALE',
  EDIT_SALE_ITEM = 'EDIT_SALE_ITEM',
  DELETE_SALE = 'DELETE_SALE',
  MANAGE_SALES = 'MANAGE_SALES',
  BULK_EDIT_SALE_ITEMS = 'BULK_EDIT_SALE_ITEMS',
}
